export const parseUploadError = (message: string) => {
  if (message.startsWith('tus:')) {
    const tusRegex =
      /response code: (?<statusCode>\d+), response text: (?<body>{.*})\n, request id/;

    try {
      const match = message.match(tusRegex);
      const statusCode = Number(match?.groups?.statusCode);
      const body = JSON.parse(match?.groups?.body || '');

      return body.message && statusCode
        ? `${body.message} (${statusCode})`
        : message;
    } catch {
      return message;
    }
  } else if (message.startsWith('Cannot add the duplicate file')) {
    const uppyRegex =
      /^Cannot add the duplicate file '(?<file>.+)', it already exists$/;

    try {
      const match = message.match(uppyRegex);
      const file = match?.groups?.file;

      return file
        ? `Die Datei '${match?.groups?.file}' kann nicht erneut hochgeladen werden.`
        : message;
    } catch {
      return message;
    }
  }

  return message;
};

export function addBackendMessageToError(response: Response, error: string) {
  return `${error} ${response.response?.message || ''}`;
}
