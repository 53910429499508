import { SkipNavContent, SkipNavLink } from '@reach/skip-nav';
import SkipNavStyles from '@reach/skip-nav/styles.css';
import UppyCoreStyles from '@uppy/core/dist/style.css';
import UppyDragDropStyles from '@uppy/drag-drop/dist/style.css';
import UppyStatusBarStyles from '@uppy/status-bar/dist/style.css';
import type { FC } from 'react';
import {
  ActionFunction,
  Link,
  Links,
  LiveReload,
  LoaderFunction,
  Meta,
  MetaFunction,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useMatches,
} from 'remix';
import Toast from '~/components/Toast';
import UploadStatus from '~/components/UploadStatus';
import svgSprite from '~/icons/sprite.svg';
import { createApi } from '~/util/api.server';
import AlertDialog from './components/AlertDialog';
import styles from './styles.css';
import tailwind from './tailwind.css';

export const meta: MetaFunction = ({ data }) => {
  return { title: data.title ? data.title : 'Servicebereich' };
};

export function links() {
  return [
    { rel: 'icon', href: '/public/favicon.ico' },
    {
      rel: 'preload',
      as: 'image',
      type: 'image/svg+xml',
      href: svgSprite,
    },
    { rel: 'stylesheet', href: tailwind },
    { rel: 'stylesheet', href: styles },
    { rel: 'stylesheet', href: SkipNavStyles },
    { rel: 'stylesheet', href: UppyCoreStyles },
    { rel: 'stylesheet', href: UppyDragDropStyles },
    { rel: 'stylesheet', href: UppyStatusBarStyles },
    { rel: 'stylesheet', href: '/theme' },
  ];
}

type LoaderData = {
  tenant: {
    title: string;
    theme: string;
    assets: Record<string, string>;
  };
};
export const loader: LoaderFunction = ({ context }) => {
  // @todo improve
  let tenant = {
    title: 'Bauer + Kirch GmbH | Servicebereich',
    theme: 'default',
    assets: {
      logo: 'logo-bk.svg',
    },
  };

  if (context.tenant) {
    tenant = context.tenant;
  }

  return { tenant };
};

export const action: ActionFunction = async ({ request }) => {
  return createApi(request).logout();
};

type NavItemProps = { to: string; value: string };
const NavItem: FC<NavItemProps> = ({ to, value }) => (
  <li key={to}>
    <a
      href={to}
      className="ring-primary rounded-sm py-1 px-2 outline-0 ring-offset-2 focus:ring-2"
    >
      {value}
    </a>
  </li>
);

type LogoProps = { src: string; alt: string };
const Logo: FC<LogoProps> = ({ src, alt }) => {
  return src ? (
    <div id="tenant-logo" className="-my-2 ml-8">
      <img src={src} alt={alt} className="h-7 w-auto" />
    </div>
  ) : null;
};

/* -------------------------------------------------------------------------- */
/*                                   Header                                   */
/* -------------------------------------------------------------------------- */
const Header = () => {
  const { tenant } = useLoaderData<LoaderData>();

  let imprintLink = '';
  let privacyLink = '';
  switch (tenant.theme) {
    case 'nos':
      imprintLink = 'https://www.nos.de/impressum/';
      privacyLink = 'https://www.nos.de/datenschutz/';
      break;
    case 'nationalpark-eifel':
      imprintLink = 'https://www.nationalpark-eifel.de/de/impressum/';
      privacyLink = 'https://www.nationalpark-eifel.de/de/datenschutz/';
      break;
    case 'dm':
    default:
      imprintLink = 'https://www.bauer-kirch.de/impressum';
      privacyLink = 'https://www.bauer-kirch.de/datenschutz';
  }

  const navItems = [
    { to: privacyLink, value: 'Datenschutzerklärung' },
    { to: imprintLink, value: 'Impressum' },
  ];

  return (
    <header className="header flex items-center overflow-hidden px-4 py-2">
      <SkipNavLink className="ring-primary rounded-sm outline-0 ring-offset-2 focus:ring-2">
        Zum Hauptinhalt wechseln
      </SkipNavLink>

      <Link
        to="/"
        className="ring-primary mr-auto inline-flex rounded-sm outline-0 ring-offset-2 focus:ring-2"
      >
        <h1 className="text-lg font-light">
          {tenant.title ? tenant.title : 'Bauer + Kirch Servicebereich'}
        </h1>
      </Link>

      <ul className="relative top-px flex gap-4 text-xs">
        {navItems.map((navItem) => (
          <NavItem key={navItem.to} to={navItem.to} value={navItem.value} />
        ))}
      </ul>

      {tenant.assets.logo ? (
        <Logo
          src={`/assets/themes/${tenant.theme}/${tenant.assets.logo}`}
          alt={tenant.title ? tenant.title : 'Logo'}
        />
      ) : null}
    </header>
  );
};

/* -------------------------------------------------------------------------- */
/*                                   Footer                                   */
/* -------------------------------------------------------------------------- */
const Footer = () => {
  return (
    <footer className="footer">
      <UploadStatus />
    </footer>
  );
};

/* -------------------------------------------------------------------------- */
/*                                    Main                                    */
/* -------------------------------------------------------------------------- */
export default function App() {
  const matches = useMatches();
  const includeScripts = matches.some((match) => !match.handle?.static);

  return (
    <html lang="de">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="root flex h-screen flex-col overflow-hidden">
        <Header />

        <Toast />
        <AlertDialog />

        <main className="flex w-full grow overflow-hidden bg-gray-100">
          <SkipNavContent />
          <Outlet />
        </main>

        <Footer />

        <ScrollRestoration />
        {includeScripts ? <Scripts /> : null}
        <LiveReload />
      </body>
    </html>
  );
}
