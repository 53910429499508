import * as Collapsible from '@radix-ui/react-collapsible';
import { StatusBar } from '@uppy/react';
import { FC } from 'react';
import { parseUploadError } from '~/util/errors';
import uppy from '~/util/upload';
import { useAlertDialogTrigger } from './AlertDialog';
import Card from './Card';
import Icon from './Icon';
import { useToastTrigger } from './Toast';

const UploadStatus: FC = () => {
  const triggerToast = useToastTrigger();
  const triggerAlertDialog = useAlertDialogTrigger();

  uppy.on('complete', (result) => {
    if (result.failed.length) {
      triggerAlertDialog(
        'Upload',
        <div className="p-4">
          {result.successful.length > 0 && (
            <Collapsible.Root className="mb-4">
              <Collapsible.Trigger asChild>
                <button type="button" className="w-full text-left">
                  <Card variant="small" className="bg-lime-500 text-white">{`${
                    result.successful.length
                  } von ${
                    result.successful.length + result.failed.length
                  } Datei${
                    result.successful.length + result.failed.length > 1
                      ? 'en'
                      : ''
                  } wurde${
                    result.successful.length + result.failed.length > 1
                      ? 'n'
                      : ''
                  } erfolgreich hochgeladen.`}</Card>
                </button>
              </Collapsible.Trigger>
              <Collapsible.Content>
                <ul className="p-4">
                  {result.successful.map((file) => (
                    <li className="relative py-1 pl-8">
                      <Icon
                        id="check-solid"
                        className="absolute left-0 top-0 text-lime-500"
                      />
                      <h3 className="font-bold">{file.name}</h3>
                    </li>
                  ))}
                </ul>
              </Collapsible.Content>
            </Collapsible.Root>
          )}

          <Collapsible.Root className="mb-4" open>
            <Collapsible.Trigger asChild>
              <button type="button" className="w-full text-left">
                <Card variant="small" className="bg-red-500 text-white">
                  Beim Upload der folgenden Datei
                  {result.failed.length > 1 ? 'en sind' : ' ist ein'} Fehler
                  aufgetreten:
                </Card>
              </button>
            </Collapsible.Trigger>
            <Collapsible.Content>
              <ul className="p-4">
                {result.failed.map((file) => {
                  return (
                    <li className="relative mb-4 pl-8">
                      <Icon
                        id="x-solid"
                        className="absolute left-0 top-0 text-red-500"
                      />
                      <h3 className="font-bold">{file.name}</h3>
                      <p>{parseUploadError(file.error)}</p>
                    </li>
                  );
                })}
              </ul>
            </Collapsible.Content>
          </Collapsible.Root>
        </div>,
        () => {
          window.location.reload();
        },
      );
    } else if (result.successful.length) {
      triggerToast(
        'Upload',
        `${result.successful.length} Datei${
          result.successful.length > 1 ? 'en' : ''
        } wurde${
          result.successful.length > 1 ? 'n' : ''
        } erfolgreich hochgeladen.`,
        'success',
      );
    }
  });

  uppy.on('restriction-failed', (file, error) => {
    if (file && error.message) {
      triggerToast('Fehler', parseUploadError(error.message), 'error');
    }
  });

  return (
    <StatusBar
      uppy={uppy}
      hideUploadButton
      hideAfterFinish
      hideRetryButton
      hidePauseResumeButton
      showProgressDetails
    />
  );
};

export default UploadStatus;
